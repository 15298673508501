import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./navbar";
import "../styles/normalize.css";
import "../styles/skeleton.css";
import { Link, Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import CookieConsent from "react-cookie-consent";

import styled from "styled-components";

const MainViewport = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url(/images/scatoire.svg) no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 3em;
  display: block;
  min-height: 85vh;
`;
export default function Layout({ children }) {
  const { t } = useTranslation();
  const {language} = useI18next();
  return (
    <>
      <Helmet>
        <title>Stéphane Catoire Website</title>
        <link rel="canonical" href="http://www.stephanecatoire.com" />
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText = {t("I consent")} >
          <Trans>
          This website uses cookies to enhance the user experience. Please refer to <Link to="/privacy" language="{language}">privacy</Link>
          for more information and ways to turn off cookie tracking.</Trans>
      </CookieConsent>
      <div>
        <Navbar />
        <MainViewport>{children}</MainViewport>
        <footer>
          <span>(c)2022 Stéphane Catoire</span>
          <br />
          <Link to="/legal" language={language}>{t("Legal")}</Link>
          &nbsp;
          <Link to="/privacy" language={language}>{t("Privacy")}</Link>
        </footer>
      </div>      
    </>
  );
}