import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

const NavbarStyled = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
`;

const LinkStyled = styled((props) => <Link {...props} />)`
  padding-left: 1em;
`;

const Brand = styled.div`
  vertical-align: center;
  display: inline-block;
`;

function menuLinks(props) {
  return (
    <StaticQuery
      query={graphql`
        query menuItems {
          site {
            siteMetadata {
              menuLinks {
                link
                title
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          {data.site.siteMetadata.menuLinks.map((path, i) => (
            <LinkStyled key={i} to={path.link}>
              <Trans>{path.title}</Trans>
            </LinkStyled>
          ))}
        </div>
      )}
    />
  );
}

export default function Navbar(props) {
  const { language, originalPath } = useI18next();
  return (
    <NavbarStyled>
      <Brand>Stéphane Catoire </Brand>
      <div>
        <Link to={originalPath} language="en">
          <img
            src="/images/Flag_of_the_United_Kingdom.svg"
            height={10}
            alt="French flag"
          />
        </Link>
        <span> / </span>
        <Link to={originalPath} language="fr">
          <img src="/images/Flag_of_France.svg" height={10} alt="UK flag" />
        </Link>
      </div>
      {menuLinks(props)}
    </NavbarStyled>
  );
}
